import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';

import NonStretchedImage from './non-stretched-image';

const styles = props => css`
  ${props.roundCorners && 'border-radius: 6px'}
`;

const Img = styled.img`
  ${styles};
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
`;

const StyledNonStretchedImage = styled(NonStretchedImage)`
  ${styles};
`;

const LazyImage = React.forwardRef((props, ref) => {
  const { url, localFile, childImageSharp, ...rest } = props;

  if (!localFile) {
    return <Img src={url} {...rest} alt={props.alt} ref={ref} lazy />;
  }

  const gatsbyImage = useMemo(() => {
    return childImageSharp || (localFile && localFile.childImageSharp);
  }, [localFile, childImageSharp]);

  if (!gatsbyImage) {
    return null;
  }

  return (
    <React.Fragment>
      <StyledNonStretchedImage
        placeholderStyle={{
          transition: 'opacity 0.65s ease-in 0.15s',
          filter: 'blur(10px)',
          left: '-2.5%',
          top: '-2.5%',
          width: '105%',
          height: '105%',
        }}
        imgStyle={{
          transition: 'opacity 0.55s ease-out',
        }}
        style={{ height: '100%' }}
        {...rest}
        fluid={gatsbyImage.fluid}
        ref={ref}
        alt={props.alt}
      />
    </React.Fragment>
  );
});

LazyImage.propTypes = {
  roundCorners: PropTypes.bool,
  localFile: PropTypes.object,
  childImageSharp: PropTypes.object,
};

export default LazyImage;
