import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import linkStripper from '../utils/link-stripper.js';
import urlParser from '../utils/urlParser';

//Component Imports
import TitleWavyBackground from '../components/titleWavyBackground';
import BlankSpace from '../components/blankSpace';
import ButtonUnderlined from '../components/ButtonUnderlined';
import LazyImage from '../components/lazy-image';

//Temp Image Import
import greenWave from '../images/squiggles/footerSquiggle.png';
import footerPrefaceRight from '../images/tmp/footerPrefaceRight.jpg';
import footerPrefaceLeft from '../images/tmp/footerPrefaceLeft.jpg';

const FooterPrefaceContainer = styled.div`
  width: 100%;
  position: relative;
  height: 600px;
  overflow: hidden;
  @media (max-width: 600px) {
    height: 350px;
  }
`;

const FooterPrefaceContainerWithImage = styled.div`
  width: 100%;
  position: relative;
  height: 800px;
  @media (max-width: 1250px) {
    height: 700px;
  }
`;

const PrefaceBackgroundContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  @media (max-width: 600px) {
    height: 350px;
  }
`;

const PrefaceBackground = styled.div`
  height: 600px;
  width: 100%;
  background-color: #131313;
  position: absolute;
  z-index: 0;
  @media (max-width: 600px) {
    height: 350px;
  }
`;

const PrefacePreText = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #7b7b7b;
  z-index: 2;
  text-align: center;
`;

const PrefaceTextContainer = styled.div`
  z-index: 20;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 600px) {
    margin-top: -30px;
  }
`;

const RightImageContainer = styled(motion.div)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  height: 475px;
  width: 350px;
  will-change: transform;
  @media (max-width: 1250px) {
    display: none;
  }
`;

const LeftImageContainer = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
  margin-top: -100px;
  will-change: transform;

  @media (max-width: 1250px) {
    display: none;
  }
`;

const LeftImageContainerInner = styled.div`
  height: 410px;
  width: 350px;
  overflow: hidden;
`;

const TitleWavyBackgroundContainer = styled.div`
  z-index: 20;
  @media (max-width: 1250px) {
    width: 90%;
    margin-left: 5%;
  }
`;

const rightImageAnims = {
  hidden: { opacity: 0, translateY: 30 },
  visible: {
    opacity: 1,
    translateY: 0,
    transition: {
      delay: 0.25,
      duration: 0.65,
    },
  },
};

const leftImageAnims = {
  hidden: { opacity: 0, translateY: 50 },
  visible: {
    opacity: 1,
    translateY: 0,
    transition: {
      delay: 0,
      duration: 0.45,
    },
  },
};

export class FooterPreface extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={350}
      >
        <React.Fragment>
          {this.props.left_aligned_image &&
          this.props.left_aligned_image.url !== null ? (
            <FooterPrefaceContainerWithImage>
              <RightImageContainer
                variants={rightImageAnims}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                {this.props.right_aligned_image && (
                  <LazyImage {...this.props.right_aligned_image} />
                )}
              </RightImageContainer>

              <LeftImageContainer
                variants={leftImageAnims}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                <LeftImageContainerInner>
                  {this.props.left_aligned_image && (
                    <LazyImage {...this.props.left_aligned_image} />
                  )}
                </LeftImageContainerInner>
              </LeftImageContainer>

              <PrefaceBackgroundContainer>
                <PrefaceTextContainer>
                  <PrefacePreText>{this.props.subtitle_text}</PrefacePreText>
                  <BlankSpace height="10px;"></BlankSpace>
                  <TitleWavyBackgroundContainer>
                    <TitleWavyBackground
                      text={this.props.main_text}
                      backgroundWave={this.props.footer_text_background_wave}
                    />
                  </TitleWavyBackgroundContainer>
                  <ButtonContainer>
                    <ButtonUnderlined
                      color={this.props.cta_color}
                      link={linkStripper(this.props.cta_link)}
                      buttonText={this.props.cta_text}
                      margin="70px 0px 0px 0px"
                      target={this.props.cta_link_target}
                    />
                  </ButtonContainer>
                </PrefaceTextContainer>
                <PrefaceBackground />
              </PrefaceBackgroundContainer>
            </FooterPrefaceContainerWithImage>
          ) : (
            <FooterPrefaceContainer>
              <PrefaceBackgroundContainer>
                <PrefaceTextContainer>
                  <PrefacePreText>{this.props.subtitle_text}</PrefacePreText>
                  <BlankSpace height="10px;"></BlankSpace>
                  <TitleWavyBackgroundContainer>
                    <TitleWavyBackground
                      text={this.props.main_text}
                      backgroundWave={this.props.footer_text_background_wave}
                    />
                  </TitleWavyBackgroundContainer>
                  <ButtonContainer>
                    <ButtonUnderlined
                      color={this.props.cta_color}
                      link={linkStripper(this.props.cta_link)}
                      buttonText={this.props.cta_text}
                      margin="70px 0px 0px 0px"
                      target={this.props.cta_link_target}
                    />
                  </ButtonContainer>
                </PrefaceTextContainer>
                <PrefaceBackground />
              </PrefaceBackgroundContainer>
            </FooterPrefaceContainer>
          )}
        </React.Fragment>
      </VisibilitySensor>
    );
  }
}

export default FooterPreface;
