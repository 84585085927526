import React from 'react';
import GatsbyImage from 'gatsby-image';

const NonStretchedImage = React.forwardRef((props, ref) => {
  let normalizedProps = props;
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        /*props.fluid.presentationWidth */
        maxWidth: '100%',
      },
      ref,
    };
  }

  return <GatsbyImage {...normalizedProps} />;
});

export default NonStretchedImage;
