import React from 'react';
import styled from '@emotion/styled';

const TitleWavyBackgroundContainer = styled.div`
  font-size: 18px;
  max-width: 800px;
  margin-top: 30px;
  span {
    font-weight: bold;
    font-style: normal;
  }
`;

const WavyTitle = styled.div`
  // background-color: #fff;
  // background-image: url(${props => props.backgroundWave});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-weight: 800;
  font-size: 50px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.02em;
  background-clip: text;
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  color: #ffffff;
  font-style: normal;
  font-family: Montserrat;
  @media (max-width: 750px) {
    font-size: 30px;
    line-height: 50px;
  }
`;

const TitleWavyBackground = props => (
  <TitleWavyBackgroundContainer>
    <WavyTitle backgroundWave={props.backgroundWave}>{props.text}</WavyTitle>
  </TitleWavyBackgroundContainer>
);

export default TitleWavyBackground;
